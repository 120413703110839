<template>
  <div
    class="text-center"
    :class="footer === 'true' ? 'contact-form-1' : 'contact-form-2'"
    @click="openContactForm">
    <p class="title">CONTACT US</p>
  </div>
</template>

<script>
  const ContactForm = () => import('@/components/modals/ContactForm.vue');
  export default {
    name  : 'ContactButton',
    props : {
      visibleFooter : String,
    },
    data() {
      return {
        footer : this.visibleFooter,
      };
    },
    methods : {

      /**
       * Open Contact Form modal
       */
      openContactForm() {
        this.$modal.show(ContactForm, {}, { height : 'auto', adaptive : true });
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/shared/contact-button";
</style>
